/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Notifications from "react-notify-toast"
import ReactDynamicImport from "react-dynamic-import"
import Header from "./header"
import "react-chat-widget/lib/styles.css"

//This method was used inorder to prevent loading before the window object becomes available
const loader = () => import("./chat")
const RealComponent = ReactDynamicImport({ loader })

const Layout = ({ link, children }) => {
  if (typeof window != "undefined") {
    var OneSignal = window.OneSignal || []
    OneSignal.push(function() {
      OneSignal.init({
        appId: "4b0c0a85-89cd-4649-a028-d23b913f3824",
      })
    })
  }

  return (
    <>
      <Notifications options={{ top: "200px" }} />
      <Header link={link} />
      <RealComponent />
      {children}
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
