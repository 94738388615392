import { Link } from "gatsby"
import React from "react"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
      show: false,
      active: props.active,
    }
  }

  toggleMenu() {
    if (!this.state.showMenu) {
      this.setState({
        show: true,
        showMenu: true,
      })
      // Set Menu State
    } else {
      this.setState({
        show: false,
        showMenu: false,
      })
    }
  }
  show(val) {
    if (this.state.show) {
      if (this.props.link === val) {
        return "nav-item show current"
      }
      return "nav-item show"
    } else {
      return "nav-item"
    }
  }

  render() {
    return (
      <header>
        <div
          className={this.state.show ? "menu-btn close" : "menu-btn"}
          onClick={() => this.toggleMenu()}
        >
          <div className="btn-line"></div>
          <div className="btn-line"></div>
          <div className="btn-line"></div>
        </div>

        <nav className={this.state.show ? "menu show" : "menu"}>
          <div
            className={this.state.show ? "menu-branding show" : "menu-branding"}
          >
            <div className="portrait"></div>
          </div>
          <ul className={this.state.show ? "menu-nav show" : "menu-nav"}>
            <li className={this.show("home")}>
              <Link to="/" className="nav-link">
                Home
              </Link>
            </li>
            <li className={this.show("about")}>
              <Link to="/about" className="nav-link">
                About Me
              </Link>
            </li>
            <li className={this.show("works")}>
              <Link to="/works" className="nav-link">
                My Work
              </Link>
            </li>
            <li className={this.show("contact")}>
              <Link to="/contact" className="nav-link">
                How to reach me
              </Link>
            </li>
            <li className={this.show("blog")}>
              <Link to="/blog" className="nav-link">
                Blog
              </Link>
            </li>
            <li className={this.show("cv")}>
              <Link to="/cv" className="nav-link">
                Cv
              </Link>
            </li>
          </ul>
        </nav>
      </header>
    )
  }
}

// Header.propTypes = {
//   siteTitle: PropTypes.string,
// }

// Header.defaultProps = {
//   siteTitle: ``,
// }

export default Header
